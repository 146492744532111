import { useState } from "react";
import { useQueryClient } from "react-query";

import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { BidStatus } from "@sellernote/_shared/src/types/forwarding/bid";

import useSnackbar from "../../../../../hooks/useSnackbar";

export default function useShipmentStatusChange({
  shipmentId,
  isImportation,
}: {
  shipmentId: number;
  isImportation: boolean;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [
    showsShipmentStatusChangeToCancelModal,
    setShowsShipmentStatusChangeToCancelModal,
  ] = useState(false);

  const [
    showsShipmentStatusChangeToWaitingForExporterInfoModal,
    setShowsShipmentStatusChangeToWaitingForExporterInfoModal,
  ] = useState(false);

  const [
    showsExportationShipmentStatusToInprogressModal,
    setShowsExportationShipmentStatusToInprogressModal,
  ] = useState(false);

  const { mutate: changeBidDetail } =
    ADMIN_BID_QUERY.useChangeBidDetail(shipmentId);

  const { mutate: changeExportBidDetail } =
    ADMIN_BID_QUERY.useChangeExportBidDetail(shipmentId);

  const { mutate: changeBidStatusToWaitingForExporterInfo } =
    ADMIN_BID_QUERY.useChangeBidStatusToWaitingForExporterInfo(shipmentId);

  const { mutate: acceptExportShipment } =
    ADMIN_BID_QUERY.useAcceptExportShipment(shipmentId);

  const handleShipmentStatusChangeToCancel = () => {
    const cancelRequestResponseHandler = {
      onSuccess: () => {
        handleSnackbarOpen("의뢰 취소로 변경했습니다.");
        setShowsShipmentStatusChangeToCancelModal(false);
        queryClient.invalidateQueries(
          ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
            bidId: shipmentId,
          })
        );
      },

      onError: () => {
        handleSnackbarOpen("요청에 실패했습니다.", "error");
      },
    };

    if (isImportation) {
      changeBidDetail(
        {
          status: "finished",
          projectStatus: "canceled",
        },
        {
          ...cancelRequestResponseHandler,
        }
      );
      return;
    }

    changeExportBidDetail(
      {
        status: "finished",
        projectStatus: "canceled",
      },
      {
        ...cancelRequestResponseHandler,
      }
    );
  };

  const handleShipmentStatusChangeToWaitingForExporterInfo = () => {
    changeBidStatusToWaitingForExporterInfo(
      {},
      {
        onSuccess: () => {
          handleSnackbarOpen("수출자 정보 입력 대기중으로 변경했습니다.");
          setShowsShipmentStatusChangeToWaitingForExporterInfoModal(false);
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: shipmentId,
            })
          );
        },

        onError: (error) => {
          if (error.code === "E086") {
            handleSnackbarOpen("이미 견적이 수락된 의뢰입니다.", "error");
            return;
          }
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleExportationShipmentStatusToInprogress = () => {
    acceptExportShipment(
      {},
      {
        onSuccess: () => {
          handleSnackbarOpen("의뢰를 진행 중으로 변경했습니다.");
          setShowsExportationShipmentStatusToInprogressModal(false);
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: shipmentId,
            })
          );
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleShipmentStatusChangeSelect = (status: BidStatus) => {
    if (status === "inProgress") {
      setShowsExportationShipmentStatusToInprogressModal(true);
      return;
    }
    if (status === "waitingForExporterInfo") {
      setShowsShipmentStatusChangeToWaitingForExporterInfoModal(true);
      return;
    }

    if (status === "finished") {
      setShowsShipmentStatusChangeToCancelModal(true);
      return;
    }

    const responseHandler = {
      onSuccess: () => {
        handleSnackbarOpen("의뢰 상태를 변경했습니다.");
        queryClient.invalidateQueries(
          ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
            bidId: shipmentId,
          })
        );
      },

      onError: () => handleSnackbarOpen("요청에 실패했습니다.", "error"),
    };

    if (shipmentId) {
      changeBidDetail({ status }, { ...responseHandler });
      return;
    }

    changeExportBidDetail({ status }, { ...responseHandler });
    return;
  };

  return {
    /** 의뢰 취소로 변경 */
    handleShipmentStatusChangeToCancel,
    showsShipmentStatusChangeToCancelModal,
    setShowsShipmentStatusChangeToCancelModal,

    /** 수출자정보 입력 상태로 변경 */
    handleShipmentStatusChangeToWaitingForExporterInfo,
    showsShipmentStatusChangeToWaitingForExporterInfoModal,
    setShowsShipmentStatusChangeToWaitingForExporterInfoModal,

    /** 수출의뢰 진행 중으로 변경 */
    showsExportationShipmentStatusToInprogressModal,
    setShowsExportationShipmentStatusToInprogressModal,
    handleExportationShipmentStatusToInprogress,

    /** 샐렉트 버튼 선택 핸들러 함수 */
    handleShipmentStatusChangeSelect,
  };
}
